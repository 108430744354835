import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import {store, persistor} from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'
import './assets/css/main.css'

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <App />
            </HashRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));